import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAppSelector } from "./redux/hooks";

export const PrivateRoute : React.FunctionComponent<RouteProps> = (props) => {
    let authenticated: boolean = useAppSelector(state => state.login.authenticated)

    if ( ! authenticated) {
        return <Route component={() => <Redirect to={"/login"}/>}/>
    }
    else { return <Route {...props}/>}
}