import styled from "styled-components";

export const HugeHeader= styled.h1<{color: string, capitalize?: boolean}>`
    color: ${props => props.color};
    display: inline-block;
    font-size: 3.5rem;
    font-weight: 400;
    margin: 0px;
    position: relative;
    text-transform: ${props => props.capitalize ? "uppercase" : "none"};
`

export const HugeHeaderBold = styled(HugeHeader)`
    font-weight: 700;
`

export const BigHeader = styled.h1<{color: string, capitalize?: boolean}>`
    color: ${props => props.color};
    display: inline-block;
    font-size: 2.5rem;
    font-weight: 400;
    margin: 0px;
    position: relative;
    text-transform: ${props => props.capitalize ? "uppercase" : "none"};
`

export const BigHeaderBold = styled(BigHeader)`
    font-weight: 700;
`

export const MediumHeader = styled.h3<{color: string, capitalize?: boolean}>`
    color: ${props => props.color};
    font-size: 1.5rem;
    position: relative;
    margin: 0px;
    text-transform: ${props => props.capitalize ? "uppercase" : "none"};
`

export const SmallHeader = styled.h3<{color: string, capitalize?: boolean}>`
    color: ${props => props.color};
    font-size: 1rem;
    margin: 0px;
    position: relative;
    text-transform: ${props => props.capitalize ? "uppercase" : "none"};
`