export const trackDownload = ( label: string ) : void => {
    // Track downloads in production environment
    if ( !(process.env.NODE_ENV === "development") ) {
        window.gtag("event", "Downloads", {
            "event_category": "Flyer",
            "event_label": label
        })
    }
}

export const trackYoutube = () : void => {
    if ( ! (process.env.NODE_ENV === "development" ) ) {
        window.gtag("event", "YouTube", {
            "event_category": "click",
            "event_label": "YouTube - iChemAnalytics"
        })
    }
}

export enum ContactEvent {
    EMAIL,
    PHONE
}

export const trackContact = (type: ContactEvent) : void => {
    if ( ! (process.env.NODE_ENV === "development" ) ) {
        let label: string;

        if ( type === ContactEvent.EMAIL ) {
            label = "email";
        }
        else {
            label = "phone";
        }

        window.gtag("event", "CustomerApproach", {
            event_label: label
        })
    }
}