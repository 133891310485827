import { configureStore } from "@reduxjs/toolkit";
import { serviceSlice, loginSlice, measurementRecordSlice, googleAnalyticsSlice } from "./reducers";
import { loggerMiddleware } from "./middleware";

export const store = configureStore({
    reducer: {
        services: serviceSlice.reducer,
        login: loginSlice.reducer,
        measurementData: measurementRecordSlice.reducer,
        googleAnalytics: googleAnalyticsSlice.reducer
    },
    middleware: [loggerMiddleware]
})

// Create additional typings based on the store for better type checking and custom hooks
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;