import { useEffect } from "react";
import { LinkProps, useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import styled from "styled-components";

export const LinkWrapper = styled.div<{underline?: boolean, color?: string, fontSize?: number, bold?: boolean}>`
    display: inline-block;

    a {
      text-decoration: ${props => props.underline ? "underline" : "none"};
      color: ${props => props.color ? props.color : "inherit"};
      font-size: ${props => props.fontSize ? props.fontSize : 1}rem;
      font-weight: ${props => props.bold ? 700 : 400};

      &:hover {
        text-decoration: underline;
      }
    }
`;

interface StandardLinkProps extends LinkProps {
  fontSize?: number
  underline?: boolean,
  color?: string,
  bold?: boolean
}

export const StandardLink : React.FunctionComponent<StandardLinkProps> = ({fontSize, underline, color, bold, ...LinkProps}) => {
  return (
    <LinkWrapper underline={underline} color={color} fontSize={fontSize} bold>
      <Link {...LinkProps} />
    </LinkWrapper>
  )
}

export const DownloadLink = styled.a<{fontSize?: number, bold?: boolean, color?: string}>`
    text-decoration: none;
    color: ${props => props.color ? props.color : "inherit"};
    font-size: ${props => props.fontSize ? props.fontSize : 1}rem;
    font-weight: ${props => props.bold ? 700 : 400};
    &:hover {
      text-decoration: underline;
    }
`

export function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
}