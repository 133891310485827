import React, { useState, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import { Menu} from "./pages/menu";
import { FooterBar } from "./pages/footer";
import { ScrollToTop } from "./styles/links";
import { store } from "./redux/store";
import { apolloClient } from "./apollo-config";
import { PrivateRoute } from "./private-route";
import { withTracker } from "./GoogleAnalytics/page-tracker";
import { device } from "./styles/devices";
import { isIE } from "react-device-detect";
import { getUserLocale } from "get-user-locale";
import i118n from "./i18.config";

const Start  = React.lazy( () => import("./pages/start"));
const AboutUs = React.lazy( () => import("./pages/about"));
const  Pruefkonzept  = React.lazy( () => import("./pages/pruefkonzept"))
const  Dienstleistungen = React.lazy( () =>  import("./pages/dienstleistungen"));
const  Publikationen  = React.lazy( () => import("./pages/publikationen"));
const Karriere = React.lazy( () => import("./pages/karriere"));
const  Kontakt  = React.lazy( () => import("./pages/kontakt"));
const  Datenschutz  = React.lazy( () => import("./pages/datenschutz"));
const  Dashboard = React.lazy( () => import("./pages/private/dashboard"));
const  Impressum  = React.lazy( () => import("./pages/impressum"));
const  Login = React.lazy( () => import("./pages/login"));
const CookieHandler = React.lazy( () => import("./pages/cookie"));
const NotFound = React.lazy( () => import("./pages/not-found"));

const GlobalThemePublic = createGlobalStyle`
    html {
        margin: 0px;
        height: 100%;
        scrollbar-width: 0%;
        scroll-behaviour: smooth;
    }
    
    body {
        margin: 0px !important;
        height: 100%;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
        line-height: 1.5;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        box-sizing: border-box;
        overflow-x: hidden;
    }
`

const GlobalThemePrivate = createGlobalStyle`
    html {
        width: 100%;
        height: 100%;
    }
    
    body {
        margin: 0px;
        height: 100%;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
        line-height: 1.5;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        overflow-x: hidden;
    }
`


const Main = styled.div`
    box-sizing: border-box;
    margin-top: 0px;
    min-height: 100vh;
    max-width: 100%;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: calc(100% - 800px) 800px; 
    grid-template-areas:
        "main"
        "footer";

    @media only screen and ${device.tablet} {
        grid-template-rows: calc(100% - 350px) 350px; 
    }
`

const MainArea = styled.div`
    grid-area: main;
`

function PublicLayout () {
    const [isMobile, setMobile] = useState(detectMobile())
    // Handle isMobile state of the application
    function detectMobile() {
        if (window.innerWidth > 768) return false;
        else return true;
    }

    const windowResized = (event: Event) => {
        if (window.innerWidth > 768) setMobile(false);
        else setMobile(true);
    }

    React.useEffect(() => {
        window.addEventListener('resize', windowResized);

        return () => {
            window.removeEventListener('resize', windowResized);
        };
    }, []);

  return (
        <div>
            <GlobalThemePublic/>
            <Main>
                <Menu isMobile={isMobile}/>
                    {/*@ts-ignore*/}
                    <CookieHandler/>
                    <MainArea>
                        <Switch>
                            <Route exact path="/" component={withTracker(Start, {}, {isMobile: isMobile})}/>
                            <Route exact path="/unternehmen" component={withTracker(AboutUs, {}, {isMobile: isMobile})}/>
                            <Route exact path="/pruefkonzept" component={withTracker(Pruefkonzept, {}, { isMobile: isMobile })}/>
                            <Route exact path="/dienstleistungen" component={withTracker(Dienstleistungen, {}, {isMobile: isMobile})}/>
                            <Route exact path="/publikationen" component={withTracker(Publikationen, {}, {isMobile: isMobile})}/>
                            <Route exact path="/karriere" component={withTracker(Karriere, {}, {isMobile: isMobile})}/>
                            <Route exact path="/login" component={withTracker(Login, {}, {isMobile: isMobile})}/>
                            <Route exact path="/kontakt" component={withTracker(Kontakt, {}, {isMobile: isMobile})}/>
                            <Route exact path="/datenschutz" component={withTracker(Datenschutz)}/>
                            <Route exact path="/impressum" component={withTracker(Impressum)}/>
                            <Route path="/*" component={withTracker(NotFound)}/>
                        </Switch>
                    </MainArea>
                <FooterBar isMobile={isMobile}/>
            </Main>
       </div>
  );
}

function PrivateLayout () {
    return (
        <div>
            <GlobalThemePrivate/>
            <Switch>
                {/*@ts-ignore*/}
                <PrivateRoute exact path={"/app/dashboard"} component={Dashboard}/>
            </Switch>
        </div>
    )
}

const LANGUAGE = getUserLocale();
LANGUAGE.match(/^de/) ? i118n.changeLanguage("de") : i118n.changeLanguage("en")

function App () {
    // Internet explorer is not supported
    if ( isIE ) {
        return (
        <div>
            <h1>Internet Explorer wird nicht unterstützt</h1>
            <p>Für ein sichereres und schnelleres Nutzererlebnis benutzen Sie einen modernen Browser wie Chrome, Firefox, Safari, Opera oder Edge.</p>
        </div>
        )
    }
    return (
        /* Provider for redux store */
        <Provider store={store}>
            {/* Provide ApolloClient for making GraphQL calls*/}
            <ApolloProvider client={apolloClient}>
                <div>
                    <Router>
                        {/* Component for scrolling to top, if redirected on a page*/}
                        <ScrollToTop/>
                        {/*@ts-ignore*/}
                        <Suspense fallback={null}>
                                <Switch>
                                    {/* Private layout for dashboard componets, Token protected */}
                                    <Route exact path={"/app/*"} component={PrivateLayout}/>
                                    {/* Public layout accessible for everyone */}
                                    <Route path={"/"} component={PublicLayout}/>
                                </Switch>
                        </Suspense>
                    </Router>
                </div>
            </ApolloProvider>
        </Provider>
    )
}
export default App;
