import styled from "styled-components";
import { device } from "../styles/devices";
import { iChemBlue } from "../styles/colors";
import { SmallHeader } from "../styles/header";
import { DownloadLink, StandardLink } from "../styles/links";
import { trackDownload } from "../GoogleAnalytics/interaction-tracker";
import { useTranslation } from "react-i18next";

const Footer = styled.div`
    grid-area: footer;
    position: relative;
    margin: 0px 0px 0px 0px;
    padding: 20px 0px 20px 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 500px;
    background-color: ${iChemBlue};
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    @media only screen and ${device.tablet} {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        min-height: 300px;
    }

    @media only screen and ${device.desktop} {
        padding: 20px 50px 20px 50px;
    }
`

const FooterBase = styled.div`
    position: absolute;
    bottom: 0pt;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    min-height: 50px;
    min-width: 100%;
`

const FooterBaseItem = styled.a`
    margin: 5px 10px;
`

const FooterImageContainer = styled.div`
    padding: 0px 10px;
`
const FooterImage = styled.img`
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;
`

const FooterMenu = styled.div<{isMobile?: boolean}>`
    color: white; 
    padding: 0px 15px;
    text-align: center;
    min-width: 100%;

    @media only screen and ${device.tablet} {
        text-align:left;
        min-width: 25%;
    }
`

const FooterList = styled.ul`
    padding: 0px;
`

const FooterListItem = styled.li`
    text-decoration: none;
    list-style: none;
    padding: 5px 0px;
`

export const FooterBar : React.FunctionComponent<{isMobile: boolean}> = (props: {isMobile: boolean}) => {
    const { i18n, t } = useTranslation()

    return (
        <Footer>
            <FooterImageContainer>
                <StandardLink to={"/"}>
                    <FooterImage src={"/icons/Logo.svg"}/>
                </StandardLink>
            </FooterImageContainer>
            <FooterMenu>
                <SmallHeader color={"white"}>Quick Links</SmallHeader>
                <FooterList>
                    <FooterListItem><StandardLink to={"/unternehmen"}>{t("footer.links.aboutUs")}</StandardLink></FooterListItem>
                    <FooterListItem><StandardLink to={"/pruefkonzept"}>{t("footer.links.philosophy")}</StandardLink></FooterListItem>
                    <FooterListItem><StandardLink to={"/dienstleistungen"}>{t("footer.links.services")}</StandardLink></FooterListItem>
                    <FooterListItem><StandardLink to={"/kontakt"}>{t("footer.links.contact")}</StandardLink></FooterListItem>
                    <FooterListItem><StandardLink to={"/publikationen"}>{t("footer.links.publications")}</StandardLink></FooterListItem>
                </FooterList>
            </FooterMenu>
            <FooterMenu>
                <SmallHeader color={"white"}>{t("footer.flyer.header")}</SmallHeader>
                <FooterList>
                    <FooterListItem>
                        <DownloadLink 
                            href={i18n.language === "de" ? "/information/Flyer-Permeation.pdf" : "/information/Flyer-Permeation-En.pdf"}
                            onClick={() => { trackDownload("Flyer-Permeation")}}
                            download>
                                {t("footer.flyer.permeation")}
                        </DownloadLink>
                    </FooterListItem>
                    <FooterListItem>
                        <DownloadLink 
                            href={i18n.language === "de" ? "/information/Flyer-Verspannungsprüfungen.pdf" : "/information/Flyer-Tension-Bending-Tests.pdf"}
                            onClick={() => { trackDownload("Flyer-Verspannungsmessungen") }}
                            download>
                                {t("footer.flyer.tension")}
                        </DownloadLink>
                    </FooterListItem>
                    <FooterListItem>
                        <DownloadLink 
                            href={i18n.language === "de" ? "/information/Infoblatt-Verspannungsprüfungen.pdf" : "/information/Info-Flyer-Tension-Bending-Tests.pdf"}
                            onClick={() => { trackDownload("Infobalatt-Verspannungsmessungen") }}
                            download>
                            {t("footer.flyer.tensionInfo")}
                        </DownloadLink>
                    </FooterListItem>
                </FooterList>
            </FooterMenu>
            <FooterMenu>
                <SmallHeader color={"white"}>{t("footer.contact.header")}</SmallHeader>
                <FooterList>
                    <FooterListItem>iChemAnalytics GmbH</FooterListItem>
                    <FooterListItem>Centrum Industrial IT (CIIT), Gebäude 1</FooterListItem>
                    <FooterListItem>Campusallee 6</FooterListItem>
                    <FooterListItem>32657 Lemgo</FooterListItem>
                    <FooterListItem>Tel.: +49 (0)5261 2809134</FooterListItem>
                    <FooterListItem>E-Mail: info@ichemanalytics.de</FooterListItem>
                </FooterList>
            </FooterMenu>
            <FooterBase>
                <FooterBaseItem>
                    <StandardLink to={"/datenschutz"}>
                        <SmallHeader color={"white"}>{t("footer.important.privacy")}</SmallHeader>
                    </StandardLink>
                </FooterBaseItem>
                <FooterBaseItem>
                    <StandardLink to={"/impressum"}>
                    <SmallHeader color={"white"}>{t("footer.important.imprint")}</SmallHeader>
                    </StandardLink>
                </FooterBaseItem>
                <FooterBaseItem>
                    <SmallHeader color={"white"}>
                        <DownloadLink href={"/information/AGB-iChemAnalytics.pdf"} bold download>
                            {t("footer.important.service")}
                        </DownloadLink>
                    </SmallHeader>
                </FooterBaseItem>
            </FooterBase>
        </Footer>
    )
}

