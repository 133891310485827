import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from "@apollo/client";
import { config } from "./config.links";

const openAPI = new HttpLink({
    uri: config.url.PUBLIC_GRAPHQL_API,
    credentials: "include"
})

const protectedAPI = new HttpLink({
    uri: config.url.PRIVATE_GRAPHQL_API,
    credentials: "same-origin",
})


export const apolloClient = new ApolloClient({
        link: ApolloLink.split( ( operation ) => operation.getContext().protected === true,
            protectedAPI,
            openAPI),
        cache: new InMemoryCache().restore({}),
});