import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { StandardLink, DownloadLink } from "../styles/links";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars, faHome, faUser, faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { fab, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { iChemBlue, iChemWhite } from '../styles/colors';
import { SmallHeader } from "../styles/header";
import { useTranslation } from "react-i18next";
import { trackYoutube } from "../GoogleAnalytics/interaction-tracker";

const Navigation = styled.div<{isMobile: boolean}>`
    position: fixed;
    cursor: pointer;
    top: 0px;
    display: flex;
    z-index: 100;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => props.isMobile ? 'flex-end' : 'center'};
    background-color: ${iChemBlue};
    height: 50px;
    width: 100%;
    min-width: 100%;
    min-height: 50px;
    max-height: 50px;
`

type IconProps = {image: string, className?: string, onClick?: any}
const Icon = (props: IconProps) => <img src={props.image} className={props.className} onClick={() => props.onClick()} alt={"iCa"}/>

const NavigationIcon = styled(Icon)`
    z-index: 100000 !important;
    position: fixed;
    left: 0pt;
    padding: 10px 10px;
    margin: 0px 50px 0px 0px; 
    align-self: center;
    max-width: 40px;
`
const NavigationItem = styled.div<{isMobile?: boolean}>`
    display: ${ props => props.isMobile ? 'none' : 'flex'};
    flex-direction: column;
    color: white;
    padding: 10px;
    margin: 0px 2%;
`
const DropdownLanguage = styled.div`
    position: relative;
    text-transform: uppercase;
`
const DropdownItem = styled.div<{display?: boolean}>`
    display: ${props => props.display? "block" : "none"};
    position: absolute;
    bottom: -45px;
    color: white;
    background-color: ${iChemBlue};
    padding: 0px 10px 10px 0px;
    border-radius: 0 0 15px 15px;

    &:before {
        content: "";
        height: 30px;
        background-color: ${iChemBlue};
    }
`

const NavigationShortIcons = styled.div<{isMobile?: boolean}>`
    display: ${props => props.isMobile ? 'none' : 'flex'};
    position: absolute; 
    align-items: center;
    right: 0px;
    color: white;

    div {
        margin-right: 10px;
        padding-left: 15px;
    }
`

const MobileBurger = styled.div<{isMobile?: boolean}>`
    display: ${props => props.isMobile ? 'flex': 'none'};
    margin-right: 10px;
`

const MobileMenu = styled.div<{isOpen?: boolean}>`
    background-color: ${iChemBlue};
    opacity: 1;
    z-index: 100;
    position: fixed;
    text-align: right;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: ${props => props.isOpen ? "translateX(0%)" : "translateX(-101%)"};
    transition: .75s;
    overflow: hidden;
`

const MobileMenuContent = styled.div<{isOpen?: boolean}>`
    display: ${props => props.isOpen ? 'flex' : 'none'};
    width: 100%;
    height: 100%;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
`

const MobileMenuItem = styled.div`
    color: white;
    font-size: 30;
    font-weight: 800;
    padding: 20px 10px;
    width: 75%;
    border-bottom: 0.2px solid white;
`

const FlagTextPair = styled.div`
    display: flex;
    flex-direction: row;
    wrap: no-wrap;
    align-items: center;
    justify-content: center;
`
const FlagIcon = styled.img`
    max-height: 30px;
    max-width: 30px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
`

export const Menu = (props: { isMobile: boolean })=> {
    const [mobileOpen, mobileMenuOpen] = useState(false);
    const [languageOpen, setLanguageOpen] = useState(false);

    const { t, i18n } = useTranslation()
    const history = useHistory();
    const goToAddress = (addr: string) => {history.push(addr)};

    if ( ! props.isMobile ) {
        return (
            <div>
            <Navigation isMobile={props.isMobile}>
                <NavigationIcon image={"/icons/Logo.svg"} onClick={() => goToAddress("/")}/>
                <NavigationItem isMobile={props.isMobile}>
                    <StandardLink to={"/unternehmen"}>
                    <SmallHeader color={iChemWhite}>
                        {t("menu.aboutUs")}
                    </SmallHeader>
                    </StandardLink>
                </NavigationItem>
                <NavigationItem isMobile={props.isMobile}>
                    <StandardLink to={"/pruefkonzept"}>
                    <SmallHeader color={iChemWhite}>
                        {t("menu.philosophy")}
                    </SmallHeader>
                    </StandardLink>
                </NavigationItem>
                <NavigationItem isMobile={props.isMobile}>
                    <StandardLink to={"/dienstleistungen"}>
                    <SmallHeader color={iChemWhite}>
                        {t("menu.services")}
                    </SmallHeader> 
                    </StandardLink>
                </NavigationItem>
                <NavigationItem isMobile={props.isMobile}>
                    <StandardLink to={"/publikationen"}>
                    <SmallHeader color={iChemWhite}>{t("menu.publications")}</SmallHeader>
                    </StandardLink>
                </NavigationItem>
                <NavigationItem isMobile={props.isMobile}>
                    <StandardLink to={"/karriere"}>
                    <SmallHeader color={iChemWhite}>{t("menu.career")}</SmallHeader>
                    </StandardLink>
                </NavigationItem>
                <NavigationItem isMobile={props.isMobile}>
                    <StandardLink to={"/kontakt"}>
                    <SmallHeader color={iChemWhite}>{t("menu.contact")}</SmallHeader>
                    </StandardLink>
                </NavigationItem>
                <NavigationShortIcons isMobile={props.isMobile}>
                        <StandardLink to={"/"}>
                            <FontAwesomeIcon icon={faHome}/>
                        </StandardLink>
                        <DownloadLink href={"https://www.youtube.com/channel/UCRBYVU_pOz36ogcxln-N1-w"} onClick={() => trackYoutube()}>
                            <FontAwesomeIcon icon={faYoutube} />
                        </DownloadLink>
                        <StandardLink to={"/login"}>
                            <FontAwesomeIcon icon={faUser}/>
                        </StandardLink>
                        <DropdownLanguage onClick={() => setLanguageOpen(!languageOpen)}>
                            <FontAwesomeIcon icon={ languageOpen ? faAngleDown : faAngleRight}/> {i18n.language}
                            <DropdownItem display={languageOpen} 
                                onClick={() => {
                                    i18n.changeLanguage(i18n.language === "de" ? "en" : "de");
                                    setLanguageOpen(false);
                                    }}>
                                {i18n.language === "de" ? "EN" : "DE"}
                            </DropdownItem>
                        </DropdownLanguage>
                </NavigationShortIcons>
            </Navigation>
            </div>
        )
    }
    else {
        return (
        <div>
            <Navigation isMobile={props.isMobile}>
                <NavigationIcon image={"/icons/Logo.svg"} onClick={() => { goToAddress("/"); mobileMenuOpen(false); }}/>
                <MobileBurger isMobile={props.isMobile} onClick={() => mobileMenuOpen(true)}>
                    <FontAwesomeIcon icon={faBars} 
                        style={{
                        'fontWeight': 800, 'fontSize': '28px', 'color': 'white', 'marginRight': '5px',
                        }} 
                        onClick={() => mobileMenuOpen(false)}></FontAwesomeIcon>
                </MobileBurger>
                <MobileMenu isOpen={mobileOpen}>
                    <FontAwesomeIcon icon={faTimes} style={{
                        'fontWeight': 800, 'fontSize': '34px', 'color': 'white', 'marginRight': '5px',
                        'marginTop': '10px', 'visibility': mobileOpen ? 'inherit' : 'hidden'
                    }
                        } onClick={() => mobileMenuOpen(false)}></FontAwesomeIcon>
                    <MobileMenuContent isOpen={mobileOpen}>
                        <MobileMenuItem>
                            <StandardLink to={"/unternehmen"} onClick={() => {mobileMenuOpen(false)}}>
                                {t("menu.aboutUs")}
                            </StandardLink>
                        </MobileMenuItem>
                        <MobileMenuItem>
                            <StandardLink to={"/pruefkonzept"} onClick={() => {mobileMenuOpen(false)}}>
                                {t("menu.philosophy")}
                            </StandardLink>
                        </MobileMenuItem>
                        <MobileMenuItem>
                            <StandardLink to={"/dienstleistungen"} onClick={() => {mobileMenuOpen(false)}}>
                                {t("menu.services")}
                            </StandardLink>
                        </MobileMenuItem>
                        <MobileMenuItem>
                            <StandardLink to={"/publikationen"} onClick={ () => {mobileMenuOpen(false)} }>
                                {t("menu.publications")}
                            </StandardLink>
                        </MobileMenuItem>
                        <MobileMenuItem>
                            <StandardLink to={"/karriere"} onClick={ () => {mobileMenuOpen(false)} }>
                                {t("menu.career")}
                            </StandardLink>
                        </MobileMenuItem>
                        <MobileMenuItem>
                            <DownloadLink 
                                href="https://www.youtube.com/channel/UCRBYVU_pOz36ogcxln-N1-w" 
                                onClick={() => {mobileMenuOpen(false)}}
                                bold={true}>
                                Youtube <FontAwesomeIcon icon={faYoutube}/>
                            </DownloadLink>
                        </MobileMenuItem>
                        <MobileMenuItem>
                            <StandardLink to={"/kontakt"} onClick={() => {mobileMenuOpen(false)}}>
                                {t("menu.contact")}
                            </StandardLink>
                        </MobileMenuItem>
                        <MobileMenuItem>
                            <StandardLink to={"/login"} onClick={() => {mobileMenuOpen(false)}}>
                                Login
                            </StandardLink>
                        </MobileMenuItem>
                        <MobileMenuItem>
                            <FlagTextPair>
                                <FlagIcon src={i18n.language === "de" ? "icons/german-flag.png" : "icons/british-flag.png"}/>
                                <div onClick={() => i18n.changeLanguage(i18n.language === "de" ? "en" : "de")}>{t("menu.language")} <FontAwesomeIcon icon={faAngleDown}/></div>
                            </FlagTextPair>
                        </MobileMenuItem>
                    </MobileMenuContent>
                </MobileMenu>
            </Navigation>
        </div>)
    }
}
