import { Middleware } from "redux";

export const loggerMiddleware : Middleware = store => next => action => {
    if ( action.type === "GoogleAnalytics/userApprovedAnalytics") {
        // if consented to Cookies, then initialize GoogleAnalytics
        if ( ! (process.env.NODE_ENV === "development") ) { // Production build
        }
        else { // Development build
        }
    }
    else if ( action.type === "login/authenticated") {
        // if user logged in, register GA event
    }
    else if ( action.type === "login/loggedOut" ) {
        // if user logged out, register GA event
    }

    return next(action);
}