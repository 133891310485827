import { createSlice, PayloadAction } from  "@reduxjs/toolkit";
import { IMeasurementRecord } from "../GraphQL/types";

interface ServicesState{
    service: number
    serviceIsModal: boolean
}

const initialServicesState : ServicesState = {
    service: 0,
    serviceIsModal: false
}

export const serviceSlice = createSlice({
    name: 'services',
    initialState: initialServicesState,
    reducers: {
        setActiveService: ( state, action: PayloadAction<{service: number, isModal: boolean}>) => {
            state.service= action.payload.service
            state.serviceIsModal = action.payload.isModal;
        }
    }
})

export const { setActiveService } = serviceSlice.actions;

// Login reducers and state
interface LoginState {
    authenticated: boolean;
    token: string | null;
    username: string | undefined;
    DeviceID: string;
}

const initialLoginState : LoginState = {
    authenticated: false,
    token: null,
    username: undefined,
    DeviceID: ""
}

export const loginSlice = createSlice({
    name: 'login',
    initialState: initialLoginState,
    reducers: {
            authenticated: ( state, action: PayloadAction<{token: string, username: string, DeviceID: string}>) => {
                state.authenticated = true;
                state.token = action.payload.token;
                state.username = action.payload.username;
                state.DeviceID = action.payload.DeviceID;
            },
            loggedOut: ( state ) => {
                state.authenticated = false;
                state.token = null;
                state.username = undefined;
                state.DeviceID = "";
            }
    }
})

export const { authenticated, loggedOut } = loginSlice.actions;

interface MeasurementState {
    TaskID: number | undefined,
    SelectedCells: string | undefined,
    ActiveIndices: (number|undefined)[] | undefined,
    data: IMeasurementRecord[][] | undefined
}

// Measurement record state and reducers
export const initialMeasurementState : MeasurementState = {
    data: undefined,
    TaskID: undefined,
    SelectedCells: undefined,
    ActiveIndices: undefined
}

export const measurementRecordSlice = createSlice({
    name: 'MeasurementRecord',
    initialState: initialMeasurementState,
    reducers: {
        setMeasurementData : ( state, action: PayloadAction<{
                data: IMeasurementRecord[][] | undefined, 
                TaskID: number | undefined, 
                SelectedCells: string | undefined,
                ActiveIndices: number[] | undefined
            }>) => {
            state.data = action.payload.data;
            state.TaskID = action.payload.TaskID;
            state.SelectedCells = action.payload.SelectedCells;
            state.ActiveIndices = action.payload.ActiveIndices;
        },
        updateActiveIndices: ( state, action: PayloadAction<{ index: number }>) => {
            if ( state.ActiveIndices && state.ActiveIndices.includes(action.payload.index) ) { // Already present, substitue with undefined to indicate absent
                state.ActiveIndices.splice(action.payload.index, 1, undefined);
            }
            else {
                if ( state.ActiveIndices ) { // Replace previous -1 with the active index
                    state.ActiveIndices.splice(action.payload.index, 1, action.payload.index);
                }
            }
        }
    }
})

export const { setMeasurementData, updateActiveIndices } = measurementRecordSlice.actions;

interface IGoogleAnalyticsState {
    consented: boolean
}

const initialGoogleAnalyticsState : IGoogleAnalyticsState = {
    // if consented is false, then GoogleAnalytics will not be initialized
    consented: false
}

export const googleAnalyticsSlice = createSlice({
    name: "GoogleAnalytics",
    initialState: initialGoogleAnalyticsState,
    reducers: {
        userApprovedAnalytics: ( state ) => {
            state.consented = true;
        }
    }
})

export const { userApprovedAnalytics } = googleAnalyticsSlice.actions;