export const iChemPurple = (opacity?: number) : string => {
    return opacity ? `rgba(255, 51, 102, ${opacity})` : "rgba(255, 51, 102)";
};
export const iChemPurpleLight = "rgba(255, 102, 204)";
export const iChemWhite : string = "white";
export const iChemOrange : string = "rgba(255, 153, 51, 1)";
export const iChemBlue : string = "rgba(2, 14, 62, 1)";
export const iChemBlueLight: string = "rgba(230, 235, 255)";
export const iChemBlueAqua : string = "rgba(51, 204, 255)";
export const iChemRed: string = "#dc004e";
export const iChemBorder: string = "#aaa9ad";

export const ImageCardGray : string = "#f5f5f5";
export const MiniCardGray : string = "#eeeeee";