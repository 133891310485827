const prod = {
    url: {
       PRIVATE_GRAPHQL_API : "https://ichemanalytics.de/pgql",
       PUBLIC_GRAPHQL_API : "https://ichemanalytics.de/gql",
       REFRESH_TOKEN_CHECK: "https://ichemanalytics.de/refresh-token",
       INVALIDATE_REFRESH_TOKEN_API : "https://ichemanalytics.de/invalidate-token",
    }
}

const dev = {
       url: {
        PRIVATE_GRAPHQL_API : "http://localhost:4000/pgql",
        PUBLIC_GRAPHQL_API : "http://localhost:4000/gql",
        REFRESH_TOKEN_CHECK: "http://localhost:4000/refresh-token",
        INVALIDATE_REFRESH_TOKEN_API : "http://localhost:4000/invalidate-token"
       }
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod;