import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Cookies } from "react-cookie-consent";
import { store } from "../redux/store";
import { userApprovedAnalytics } from "../redux/reducers";

interface IWrappedProps extends RouteComponentProps {
    isMobile?: boolean
};

// Wrapper component for tracking page visits 
export const withTracker = ( WrappedComponent: React.ComponentType<IWrappedProps>, options = {}, componentProps = {}) => {
    const trackPage = (page: string) => {
        // User opted in for Google Analytics and allows tracking
        if ( store.getState().googleAnalytics.consented ) {
        }
        // Cookie was found, but state was not yet set
        else if ( Cookies.get("ichemanalytics-consent") ) {
            // Set to user approved cookies
            store.dispatch(userApprovedAnalytics())
        }
    }

    return (props: IWrappedProps) => {
        useEffect(() => {
            // if location pathname changed, trigger new call to GoogleAnalytics
            trackPage(props.location.pathname);
        }, [props.location.pathname])
        return <WrappedComponent {...props} {...componentProps}/>
    };
}

