import i118n from "i18next";
import { initReactI18next } from "react-i18next";
//@ts-ignore
import translation from "./texts/translation-text.yaml";

const resources = {
    de: {
        translation: translation.de
    },
    en: {
        translation: translation.en
    }
}


i118n
    .use(initReactI18next)
    .init({
        resources,
        lng: "de",
        fallbackLng: "de",
    })



export default i118n;